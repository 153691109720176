<template>
  <div class="all">
    <!--    顶部Banner-->
    <div class="topTitle">
      <div class="title">
        <!--        顶部logo-->
        <div class="imgLogo" @click="home"><img src="../../assets/img/logo2.png" class="home">
          <p>环宇建筑科技股份有限公司</p>
        </div>
      </div>
      <div class="registered">
        <div class="msg">企业信息</div>
        <div class="dv1">
          <!--          左侧-->
          <div class="d_r">
            <div><p class="p_title"><span>*</span> 供应商名称：</p>
              <el-input v-model="form.supplierName" placeholder="请输入您的供应商名称" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 联系地址：</p>
              <el-input v-model="form.contactAddress" placeholder="请输入您的联系地址" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 营业期限：</p>
<!--              <el-input v-model="form.businessTerm" placeholder="开始时间"></el-input>-->
              <span style="font-size: 12px;">开始时间: </span>
              <el-date-picker
                style="width: 30%;"
                v-model="form.businessTermBeginTime"
                value-format="yyyy-MM-dd">
              </el-date-picker>
              <span style="font-size: 12px; margin-left: 10px"> 结束时间: </span>
              <el-date-picker
                style="width: 30%;"
                v-model="form.businessTermEndTime"
                value-format="yyyy-MM-dd"
                :value="form.businessTermEndTime">
              </el-date-picker>
              <el-button type="primary" style="padding: 8px 20px; border-radius: 20px" @click="foverBut">永久</el-button>
            </div>
            <div><p class="p_title"><span>*</span> 法定代表人联系电话：</p>
              <el-input v-model="form.legalPersonPhone" maxlength="11" placeholder="请输入联系电话" style="width: 47%; margin-right: 12px"></el-input>
                <el-input v-model="codeS" class="input_p" placeholder="请输入验证码" style="width: 30%; margin-right: 4px"></el-input>
                <span v-show="show" :datafld="!show" class="code_get" @click="code_time">获取验证码</span>
                <span v-show="!show" class="code_get">{{ code }}秒后获取</span>
            </div>
            <div><p class="p_title"><span>*</span> 联系人身份证号码：</p>
              <el-input @blur="userID" v-model="form.contactsIdNumber" maxlength="18" placeholder="请输入您的联系人身份证号码"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 纳税人识别号：</p>
              <el-input v-model="form.identifyingCode" placeholder="请输入您的纳税人识别号" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 开户银行：</p>
              <el-input v-model="form.depositBank" placeholder="请输入您的开户银行" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 主要经营类别：</p>
              <el-select v-model="form.businessCategory" placeholder="请选择您的主要经营类别" @change="getContent">
                <el-option v-for="(item, index) in material" :key="index" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>
            <div><p class="p_title"><span>*</span> 主要经营内容：</p>
              <div class="contentDu">
                <el-select  v-model="businessContent" placeholder="请选择您的主要经营内容" clearable  @change="inputMulti">
                  <el-option v-for="(item, index) in materialCont" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
                 <el-input style="width: 300px" v-model="businessContentCustom" placeholder="自定义内容" maxlength="100"></el-input>
              </div>
            </div>
          </div>
          <!--右侧-->
          <div class="d_l">
            <div><p class="p_title"><span>*</span> 注册地址：</p>
              <el-input v-model="form.registerAddress" placeholder="请输入您的注册地址" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 注册资本/实缴资本：</p>
              <el-input v-model="form.capital" placeholder="请输入您的注册资本/实缴资本" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 法定代表人：</p>
              <el-input v-model="form.legalPerson" placeholder="请输入您的法定代表人" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 联系人：</p>
              <el-input v-model="form.contacts" placeholder="请输入您的联系人" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 联系人联系电话：</p>
              <el-input @blur="setValue" v-model="form.contactsPhone" maxlength="11" placeholder="请输入您的联系人联系电话"></el-input>
              <p style="font-size: 12px;color: red;line-height: 20px">* 此账号后续接收消息所用！</p>
            </div>
            <div><p class="p_title"><span>*</span> 税务信用等级：</p>
<!--              <el-input v-model="form.creditRating" placeholder="请输入您的税务信用等级"></el-input>-->
              <el-select v-model="form.creditRating" placeholder="请选择您的税务信用等级" style="width: 100%">
                <el-option
                  v-for="item in lv"
                  :key="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div><p class="p_title"><span>*</span> 账户号码：</p>
              <el-input v-model="form.accountNumber" placeholder="请输入您的账户号码" maxlength="100"></el-input>
            </div>
            <div><p class="p_title"><span>*</span> 主要经营区域：</p>
              <el-cascader
                size="large"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange">
              </el-cascader>
            </div>
          </div>
        </div>
        <div class="msg1">近二年来参与项目情况（业绩）</div>
        <div class="cat" @click="addSave()">+ 添加业绩</div>
        <div class="list_tab">
          <div class="top_tab">
            <div class="title_xh">序号</div>
            <div class="title_l">施工单位</div>
            <div class="title_l">工程名称</div>
            <div class="title_l">项目经理及联系电话</div>
            <div class="title_l">时间及完成程度</div>
            <div class="title_l">操作</div>
          </div>
          <div v-for="(item,index) in performanceList" :key="index" class="top_tab1">
            <div class="title_xh_re">{{ item.serialNumber }}</div>
            <div class="title_l_r">
              <el-input v-model="item.constructionUnit" class="el-input" placeholder="请输入施工单位"></el-input>
            </div>
            <div class="title_l_r">
              <el-input v-model="item.projectName" class="el-input" placeholder="请输入工程名称"></el-input>
            </div>
            <div class="title_l_r">
              <el-input v-model="item.ManagerAndPhone" class="el-input"
                        placeholder="请输入项目经理及联系电话"></el-input>
            </div>
            <div class="title_l_r">
              <el-input v-model="item.degreeComplete" class="el-input" placeholder="请输入时间及完成程度"></el-input>
            </div>
            <div class="title_l_r">
              <button class="del" @click="del(index)">删除</button>
            </div>
          </div>
        </div>
        <el-button class="save" @click="saveMe" :disabled="messageSave">保存信息</el-button>
      </div>

      <!--      近二年来参与项目情况（业绩）-->
      <div class="results">
        <div class="file registration">
          <span><i style="color: red">*</i>附件：</span>
          <el-upload
            accept=".jpg,.png,.bmp,.txt,.doc,.wps,.docx,.xls,.xlsx,.pdf,.rar,.zip"
            :headers="headers"
            :on-preview="handlePictureCardPreview"
            :on-change="feiladd"
            action='https://www.zjhy.cc/api/seeyon/rest/euAttachment'
            :auto-upload="false"
            :data="fileData"
            :show-file-list="true"
            :on-success="fileTrue"
            :on-error = "errorFile"
            ref="upload"
            :disabled="userMessage"
            :on-progress="uploadProgress">
            <el-button class="upload-button" size="small" type="primary" @click="messageList1">{{filename}}</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
          <el-button class="upload-button" size="small"  @click="messageList">{{filenameUp}}</el-button>
          <!--          文件列表展示-->
          <div v-show="fileUp.length>0" class="transition-box" v-for="(item,index) in fileUp" :key="index">
            <div>{{item.fileName}}</div>
            <div @click="filedel(item)" style="font-size: 20px">×</div>
          </div>
          <p>请上传企业营业执照、税务登记证、资质证书、基本账户信息、法人委托书、身份证、参与项目合同、结算单等复印件</p>
        </div>
        <div class="login_d" @click="tep_btn">注册</div>
        <div class="bm">@ 2021 环宇集团采招平台 版权所有 法律声明</div>
      </div>
    </div>
    <div style="display: none"></div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveHome, VtPhone, getContentApi, getNumberApi, enterprise, MyEnterprise } from '@/api/api'
import { regionData, CodeToText } from 'element-china-area-data'

const TIME_COUNT = 60 // 验证码倒计时
export default {
  name: 'registration_step',
  data () {
    return {
      filename: '选择文件',
      filenameUp: '上传文件',
      // hideDiv: true,
      // hideInput: false,
      options: regionData,
      selectedOptions: [],
      material: [],
      materialCont: [],
      codeS: '',
      message: '',
      phone: '',
      pwd: '',
      new_pwd: '',
      show: true,
      code: '',
      timer: null,
      checked: false,
      tabs: [
        {
          name: '企业注册',
          msg: true
        },
        {
          name: '个人注册',
          msg: false
        }
      ],
      lv: ['A', 'B', 'C', 'D'],
      dialogImageUrl: '',
      dialogVisible: false,
      form: {
        supplierName: '', // 名称
        registerAddress: '', // 注册地址
        contactAddress: '', // 联系地址
        capital: '', // 注册资本/实缴资本
        businessTermBeginTime: '', // 营业期限 开始
        businessTermEndTime: '', // 营业期限 结束
        legalPerson: '', // 法定代表人
        legalPersonPhone: '', // 法定代表人联系电话
        contacts: '', // 联系人
        contactsIdNumber: '', // 联系人身份证号码
        contactsPhone: '', // 联系人联系电话
        identifyingCode: '', // 纳税人识别号
        creditRating: '', // 税务信用等级
        depositBank: '', // 开户银行
        accountNumber: '', // 账户号码
        businessCategory: '', // 主要经营类别
        businessContent: '', // 主要经营内容
        businessArea: '', // 经营区域
        regionId: '' // 经营区域ID
      },
      performanceList: [{
        serialNumber: 1, // 序号
        constructionUnit: '', // 施工单位
        projectName: '',
        ManagerAndPhone: '', // 项目经理及联系电话
        degreeComplete: '' // 时间及完成程度
      }],
      date: '',
      fileData: {
        reference: '',
        subReference: ''
      },
      fileList: 0,
      businessContentCustom: '', // 自定义内容
      businessContent: '', // 自定义内容
      tipsText: '',
      inputValue: '',
      fileSize: 0,
      fileBoole: false,
      userMessage: true, // 判断用户信息填写是否完整
      fileUp: [],
      messageSave: false,
      id: ''
    }
  },
  created () {
    this.getContent()
    // this.contMesg()
  },
  computed: {
    headers () {
      const token = localStorage.getItem('token')
      return {
        token: token
      }
    }
  },
  methods: {
    // 文件上传判断是否填写完毕保存信息
    messageList1 () {
      if (this.userMessage === true) {
        this.$message.error('请成功保存信息后再上传文件！')
      } else if (this.userMessage === false) {
        this.filename = '已选择的文件'
      }
    },
    // 文件上传判断是否填写完毕保存信息
    messageList () {
      if (this.userMessage === true) {
        this.$message.error('请成功保存信息后再上传文件！')
      } else if (this.userMessage === false) {
        this.filenameUp = '已上传的文件'
        this.$refs.upload.submit()
      }
    },
    // 文件上传成功返回的信息
    fileTrue () {
      axios.defaults.headers.token = localStorage.getItem('token')
      MyEnterprise({
        method: 'getAttachment',
        reference: this.fileData.reference
      }, res => {
        if (res.data.data === '-1') {
          this.$message.error(res.data.message)
        } else {
          this.$message.success('上传文件成功！')
          this.fileUp = res.data.data
        }
      })
    },
    // 上传文件失败返回的信息
    errorFile () {
      this.$message.error('上传附件失败，请重新上传！')
    },
    saveMe () {
      enterprise({
        method: 'getsupplierPhone',
        contactsPhone: this.form.contactsPhone
      }, res => {
        if (res.data.data === '1') {
          this.$message.error(res.data.message)
        } else if (res.data.data === '0') {
          this.chooseContent()
          const form = this.form
          if (form.supplierName === '' || form.supplierName == null) {
            this.$message.error('请输入供应商名称')
            return
          } else if (form.contactAddress === '' || form.contactAddress == null) {
            this.$message.error('请输入联系地址')
            return
          } else if (form.businessTermBeginTime === '' || form.businessTermBeginTime == null) {
            this.$message.error('请输入营业期限开始时间')
            return
          } else if (form.businessTermEndTime === '' || form.businessTermEndTime == null) {
            this.$message.error('请输入营业期限结束时间')
            return
          } else if (form.legalPersonPhone === '' || form.legalPersonPhone == null) {
            this.$message.error('请输入法定代表人联系电话')
            return
          } else if (this.message === '' || this.message == null) {
            this.$message.error('请先获取验证码')
            return
          } else if (this.codeS === '' || this.codeS == null) {
            this.$message.error('请输入验证码')
            return
          } else if (this.message !== this.codeS) {
            this.$message.error('验证码错误')
            return
          } else if (form.contactsIdNumber === '' || form.contactsIdNumber == null) {
            this.$message.error('请输入联系人身份证号码')
            return
          } else if (form.identifyingCode === '' || form.identifyingCode == null) {
            this.$message.error('请输入纳税人识别号')
            return
          } else if (form.depositBank === '' || form.depositBank == null) {
            this.$message.error('请输入开户银行')
            return
          } else if (form.registerAddress === '' || form.registerAddress == null) {
            this.$message.error('请输入您的注册地址')
            return
          } else if (form.capital === '' || form.capital == null) {
            this.$message.error('请输入您的注册资本/实缴资本')
            return
          } else if (form.legalPerson === '' || form.legalPerson == null) {
            this.$message.error('请输入您的法定代表人')
            return
          } else if (form.contacts === '' || form.contacts == null) {
            this.$message.error('请输入您的联系人')
            return
          } else if (form.contactsPhone === '' || form.contactsPhone == null) {
            this.$message.error('请输入您的联系人联系电话')
            return
          } else if (form.creditRating === '' || form.creditRating == null) {
            this.$message.error('请输入您的税务信用等级')
            return
          } else if (form.accountNumber === '' || form.accountNumber == null) {
            this.$message.error('请输入您的账户号码')
            return
          } else if (form.businessArea === '') {
            this.$message.error('请选择主要经营区域')
            return
          } else if (this.funcChina(form.legalPersonPhone) === false) {
            this.$message.error('法定代表人联系电话不能含有中文')
            return
          } else if (this.funcChina(form.contactsIdNumber) === false) {
            this.$message.error('身份证号不能含有中文')
            return
          } else if (this.funcChina(form.identifyingCode) === false) {
            this.$message.error('纳税人识别号不能含有中文')
            return
          } else if (this.funcChina(form.contactsPhone) === false) {
            this.$message.error('联系人电话不能含有中文')
            return
          } else if (this.funcChina(form.accountNumber) === false) {
            this.$message.error('账户号码不能含有中文')
            return
          } else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(form.legalPersonPhone)) {
            this.$message.error('请输入正确的法定代表人联系电话')
            return
          } else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(form.contactsPhone)) {
            this.$message.error('请输入正确的联系人电话')
            return
          } else if (form.supplierName.length < 4) {
            this.$message.error('供应商名字不能少于四位')
            return
          } else if (form.contactsIdNumber.length !== 18) {
            this.$message.error('请输入正确的身份证号')
            return
          } else if (form.businessCategory === undefined || form.businessCategory === null) {
            this.$message.error('请选择主要经营类别')
            return
          } else if (form.businessContent === '' || form.businessContentCustom === null) {
            this.$message.error('请选择经营内容')
            return
          }
          const date = new Date()
          const year = date.getFullYear() // 月份从0~11，所以加一
          const dateArr = [date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()]
          for (let i = 0; i < dateArr.length; i++) {
            if (dateArr[i] >= 1 && dateArr[i] <= 9) {
              dateArr[i] = '0' + dateArr[i]
            }
          }
          const strDate = year + '-' + dateArr[0] + '-' + dateArr[1] + ' ' + dateArr[2] + ':' + dateArr[3] + ':' + dateArr[4]
          this.date = strDate
          axios.defaults.headers.token = localStorage.getItem('token')
          // 纳税识别号
          getNumberApi({
            method: 'getIdentifyingCode',
            identifyingCode: this.form.identifyingCode
          }, numRes => {
            if (numRes.data.success === false || numRes.data.data === '1') {
              this.$message.error(numRes.data.message)
            } else {
              this.userMessage = true
              enterprise({
                method: 'updateEnterpriseSupply',
                data: [
                  {
                    registerNumber: '',
                    supplierClassify: '',
                    supplierCategory: '',
                    supplierName: this.form.supplierName,
                    registerAddress: this.form.registerAddress,
                    contactAddress: this.form.contactAddress,
                    capital: this.form.capital,
                    // businessTerm: this.form.businessTerm,
                    businessTermBeginTime: this.form.businessTermBeginTime, // 营业期限 开始
                    businessTermEndTime: this.form.businessTermEndTime, // 营业期限 结束
                    legalPerson: this.form.legalPerson,
                    legalPersonPhone: this.form.legalPersonPhone,
                    contacts: this.form.contacts,
                    contactsIdNumber: this.form.contactsIdNumber,
                    contactsPhone: this.form.contactsPhone,
                    identifyingCode: this.form.identifyingCode,
                    creditRating: this.form.creditRating,
                    depositBank: this.form.depositBank,
                    accountNumber: this.form.accountNumber,
                    businessCategory: this.form.businessCategory,
                    businessContent: this.form.businessContent,
                    businessArea: this.form.businessArea,
                    subs: this.performanceList,
                    regionId: this.form.regionId,
                    supplyType: 'company'
                  }
                ]
              }, res => {
                // 判断请求是否成功
                if (res.data.success === false) {
                  this.$message.error(res.data.message)
                  return
                }
                // 附件
                this.fileData.reference = res.data.data.reference
                this.fileData.subReference = res.data.data.subReference
                this.id = res.data.data.id
                this.$store.state.regID = res.data.data.id
                this.RegStep()
              })
            }
          })
        }
      })
    },
    // 联系人手机号验证
    setValue (e) {
      if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.form.contactsPhone) || this.form.contactsPhone === '') {
        this.$message.error('请输入正确的手机号码！')
        this.form.contactsPhone = ''
      }
    },
    // 身份证校验
    userID () {
      if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.form.contactsIdNumber) || this.form.contactsIdNumber === '') {
        this.$message.error('请输入正确的身份证号！')
        this.form.contactsIdNumber = ''
      }
    },
    // 主要经营区域
    handleChange (value) {
      this.form.regionId = value.join(',')
      const a = value[0]
      const b = value[1]
      const c = value[2]
      const bus = CodeToText[a] + CodeToText[b] + CodeToText[c]
      this.form.businessArea = bus
    },

    // 获取经营类别
    getContent (val) {
      this.form.businessCategory = val
      getContentApi({
        method: 'getMaterial'
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
          return
        }
        this.material = res.data.data
        this.inputMulti()
      })
    },
    // 选中类别后选中内容
    inputMulti (val) {
      getContentApi({
        method: 'getSmallMaterial',
        largeClass: this.form.businessCategory
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
        } else {
          this.materialCont = res.data.data
          this.businessContent = val
        }
      })
    },
    foverBut () {
      this.form.businessTermEndTime = '2999-12-31'
    },
    feiladd (file, fileList) {
      // 文件类型过滤
      const fileType = file.name.replace(/.+\./, '')
      if (['jpg', 'png', 'bmp', 'txt', 'doc', 'wps', 'docx', 'xls', 'xlsx', 'pdf', 'rar', 'zip'].indexOf(fileType.toLowerCase()) === -1) {
        this.$msgbox({
          title: '',
          message: '文件格式错误，请重新上传。',
          type: 'warning'
        })
        fileList.splice(-1)
      } else {
        // 文件大小过滤
        this.fileSize = Number(file.size / 1024 / 1024)
        if (this.fileSize > 10) {
          this.$msgbox({
            title: '',
            message: '文件大小不能超过10MB，请重新上传。',
            type: 'warning'
          })
          fileList.splice(-1)
        }
      }
      this.fileList = fileList.length
    },
    filedel (item) {
      axios.defaults.headers.token = localStorage.getItem('token')
      MyEnterprise({
        method: 'delAttBy',
        ascId: item.ascId
      }, res => {
        if (res.data.success === true) {
          this.$message.success('删除成功！')
          this.fileUp.splice(this.fileUp.indexOf(item), 1)
        } else {
          this.$message.error('删除失败！')
        }
      })
    },
    uploadProgress (event, file, fileList) {
      return false
    },
    addSave () {
      this.performanceList.push({
        serialNumber: this.performanceList[this.performanceList.length - 1].serialNumber + 1,
        constructionUnit: '', // 施工单位
        projectName: '',
        ManagerAndPhone: '', // 项目经理及联系电话
        degreeComplete: '' // 时间及完成程度
      })
    },
    phone_gz () {
      if (!/^1[0-9]{10}$/.test(this.phone)) {
        this.phone = ''
        this.$message.error('请输入正确的电话号码')
      }
    },
    msg_but (i) {
      if (i === 0) {
        this.tabs[i].msg = true
        this.tabs[1].msg = false
      } else if (i === 1) {
        this.tabs[0].msg = false
        this.tabs[1].msg = true
      }
    },
    code_time () {
      if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.form.legalPersonPhone) || this.form.legalPersonPhone === '') {
        this.$message.error('请输入正确的手机号码！')
        this.form.legalPersonPhone = ''
      } else {
        if (!this.timer) {
          this.code = TIME_COUNT
          this.show = false
          axios.defaults.headers.token = localStorage.getItem('token')
          VtPhone({
            method: 'authCode',
            phone: this.form.legalPersonPhone
          }, res => {
            this.$message.success('验证码已发送至您的手机！')
            this.message = res.data.data
            // this.codeS = res.data.data.data
          })
          this.timer = setInterval(() => {
            if (this.code > 0 && this.code <= TIME_COUNT) {
              this.code--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      }
    },
    chooseContent () {
      if (this.businessContent === undefined) {
        this.businessContent = ''
      }
      if (this.businessContent === '') {
        this.$message.error('请选择主要经营内容！')
      } else if (this.businessContentCustom === '') {
        this.form.businessContent = this.businessContent
      } else if (this.businessContent !== '' && this.businessContentCustom !== '') {
        this.form.businessContent = this.businessContent + ',' + this.businessContentCustom
      }
    },
    async tep_btn () {
      if (this.fileList === 0) {
        this.$message.error('请上传附件')
      } else if (this.fileUp.length < 1) {
        this.$message.error('请上传附件')
      } else {
        await getContentApi({
          method: 'launchOa',
          supplierId: this.id
        }, res => {
        })
        this.$message.success('已提交审核')
        setTimeout(res => {
          this.$router.replace('/login/login')
        }, 2000)
      }
    },
    RegStep () {
      axios.defaults.headers.token = localStorage.getItem('token')
      saveHome({
        method: 'updateMember',
        data: [{
          unitId: this.$store.state.regID + '',
          name: this.form.supplierName,
          phone: localStorage.getItem('phone'),
          password: localStorage.getItem('pwd'),
          accountType: 'main',
          supplyType: 'company'
        }]
      }, res => {
        if (res.data.success === false) {
          this.$message.error(res.data.message)
        } else {
          this.$message({
            message: '保存信息成功！',
            type: 'success'
          })
          // 保存成功后禁止再次保存
          this.messageSave = true
          // 保存信息成功后可上传文件
          this.userMessage = false
          // this.$refs.upload.submit()// 文件上传
        }
      })
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    home () {
      this.$router.replace('/')
    },
    del (i) {
      if (this.performanceList.length === 1) {
        return
      }
      this.performanceList.splice(i, 1)
    },
    funcChina (obj) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(obj)) {
        // alert('不能含有汉字！')
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
.transition-box{
  width: 100%;
  background-color: #fdfdfe;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  padding: 0px 20px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 5px auto;
}
.transition-box:hover{
  width: 100%;
  background-color: #f5f5f5;
  color: #4a74c4;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.save{
  width: 200px;
  background-color: #10539B;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  color: white;
  margin: 0 auto;
  border-radius: 4px;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
}
.home {
  cursor: pointer;
}

.all {
  width: 100%;
  margin: 0 auto;
  background-color: #8d8d8d;
}

/*顶部title*/
.topTitle {
  width: 100%;
  height: 300px;
  background-color: #ffffff;
  background-image: url("../../assets/img/regi.png");
  background-size: 100% 100%;
}

.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
}
.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}

.registered {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 100px auto;
  width: 1200px;
  padding: 20px 20px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
}

.msg {
  margin-top: 20px;
  padding-left: 10px;
  margin-left: 20px;
  width: 100px;
  height: 23px;
  border-left: 4px solid #10539B;
  color: #333333;
  font-size: 20px;
}

.dv1 {
  display: flex;
  justify-content: space-between;
  width: 88%;
  height: 90%;
  margin: 0px auto;
}

.d_r {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 47%;
  height: 95%;
}

.d_l {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 47%;
  height: 95%;
}

.p_title {
  color: #333333;
  font-size: 16px;
  line-height: 40px;
}

.p_title > span {
  color: #FF0000;
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1200px;
  padding: 20px 20px;
  background-color: white;
  margin: 0 auto;
  border: 1px solid #e3e3e3;
}

.msg1 {
  margin-top: 20px;
  padding-left: 10px;
  margin-left: 20px;
  width: 290px;
  height: 23px;
  border-left: 4px solid #10539B;
  color: #333333;
  font-size: 20px;
}

.list_tab {
  width: 1040px;
  min-height: 100px;
  /*background-color: #333333;*/
  margin: 50px auto;
}

.top_tab {
  display: flex;
  text-align: center;
  line-height: 50px;
}

.top_tab1 {
  display: flex;
  text-align: center;
  line-height: 50px;
}

.title_xh {
  width: 120px;
  height: 50px;
  background-color: #F2F3F5;
}

.title_xh_re {
  width: 120px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #EEEEEE;
}

.title_l {
  width: 230px;
  height: 50px;
  background-color: #F2F3F5;
}

.title_l_r {
  width: 230px;
  height: 50px;
  border: 1px solid #EEEEEE;
  background-color: #ffffff;
}

.title_l_r >>> .el-input__inner {
  outline: none;
  border-style: none;
  text-align: center;
}

.cat {
  /*width: 50px;*/
  color: #0D5091;
  font-size: 14px;
  /*background-color: #4a74c4;*/
  margin: 20px auto;
  cursor: pointer;
}

.file {
  width: 840px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 80px;
  margin-top: 20px;
  line-height: 50px;
  color: #333333;
  font-size: 16px;
}

.file > p {
  font-size: 14px;
  color: #FF0000;
}

.bm {
  width: 300px;
  margin: 0px auto;
  padding-top: 80px;
  text-align: center;
  font-size: 14px;
  color: #666666;
}

/*注册按钮*/
.login_d {
  width: 200px;
  background-color: #10539B;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  color: white;
  margin: 0 auto;
  border-radius: 4px;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
}

.del {
  border: none;
  background-color: #379ff6;
  padding: 6px 18px;
  border-radius: 6px;
  color: #FFFFFF;
}
.contentDu{
  display: flex;
  justify-content: space-between;
}
.code_get {
  text-align: center;
  background-color: #379ff6;
  color: #FFFFFF;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 10px;
  font-size: 12px;
}

.upload-button {
  width: 80px;
  padding: 10px 4px;
  border-radius: 20px;
  background-color: #1789e5;
  color: #FFFFFF;
  border: none;
}

.registration .el-upload-list {
  width: 200px;
}
</style>
